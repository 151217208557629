import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';
import RTL from './components/RTL';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import { SettingsConsumer } from './contexts/SettingsContext';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import './i18n';
import gtm from './lib/gtm';
import routes from './routes';
import { createTheme } from './theme';

const App = () => {
  const content = useRoutes(routes);
  const auth = useAuth();

  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <SettingsConsumer>
        {({ settings }) => (
          <ThemeProvider
            theme={createTheme({
              direction: settings.direction,
              responsiveFontSizes: settings.responsiveFontSizes,
              mode: settings.theme,
            })}
          >
            <RTL direction={settings.direction}>
              <CssBaseline />
              <Toaster position="top-center" />
              {/*               <SettingsButton /> */}
              {auth.isInitialized ? content : <SplashScreen />}
            </RTL>
          </ThemeProvider>
        )}
      </SettingsConsumer>
    </StyledEngineProvider>
  );
};

export default App;
