import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  userLogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getSession: () => Promise.resolve(),
  userLoginFromToken: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  async function getSession() {
    const response = await axios.get('/account');
    if (!response.isAxiosError) {
      return response.data;
    }
    return null;
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');

        if (accessToken) {
          const user = await getSession();
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  // Used to authenticate user from jwt token.
  const userLoginFromToken = async (jwt) => {
    sessionStorage.setItem('accessToken', jwt);

    const user = await getSession();

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const userLogin = async (username, password) => {
    // const accessToken = await authApi.login({ email, password });
    // const user = await authApi.me(accessToken);

    try {
      const response = await axios.post('/authenticate', {
        username,
        password,
        rememberMe: false,
      });
      if (!response.isAxiosError) {
        const accessToken = response.headers.authorization;
        if (accessToken && accessToken.slice(0, 7) === 'Bearer ') {
          const jwt = accessToken.slice(7, accessToken.length);
          sessionStorage.setItem('accessToken', jwt);
        }
      }
    } catch (error) {
      toast.error(error.response.data.title);
    }

    const user = await getSession();

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    sessionStorage.removeItem('accessToken');
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, login, password, langKey = 'en') => {
    const response = await axios
      .post('/register', {
        login,
        email,
        password,
        langKey,
      })
      .catch((error) => {
        toast.error(error.response.data.title);
      });

    // if (!response.isAxiosError) {
    //   console.log("REGISTRATION RESPONSE", response);
    // }
    // console.log("axios error response", response);
    // dispatch({
    //   type: "REGISTER",
    //   payload: {
    //     user,
    //   },
    // });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        userLogin,
        logout,
        register,
        getSession,
        userLoginFromToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
